import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gt-scores-no-ondorio" }
const _hoisted_2 = { class: "gt-scores-no-ondorio__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('OTHERS.no_ondorio_role_header')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Card, null, {
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('OTHERS.no_ondorio_role')), 1)
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}