
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { loginStatus } from '@/store';
import { LoginStatus } from '@/models';
import Card from '@/components/shared/Card.vue';
import Header from '@/components/shared/Header.vue';


export default defineComponent ({
  components: { Card, IonPage, IonContent, IonButton, Header },
  data () {
    return {
      contactUsUrl: 'https://dlanauczyciela.pl/kontakt',
      LoginStatus: LoginStatus
    }
  },
  computed: {
    status (): number | null {
      return loginStatus.value
    }
  }
});

